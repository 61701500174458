import { defineStore } from 'pinia';
import api from '@/api/api';
import statusExerciseEnum from '@/enums/statusExerciseEnum';
import groupsExercisesEnum from '@/enums/groupsExercisesEnum';

export const useExerciseStore = defineStore('exercises', {
    state: () => {
        return {
            exercises : [],
            currentIdExercise: null,
            currentExercise: null
        }
    },
    persist: true,
    getters: {
        hasNextExercise: (state) => {
            return (currentExerciseOrder) => state.exercises[currentExerciseOrder + 1];
        },

        hasPreviousExercise: (state) => {
            return (currentExerciseOrder) => state.exercises[currentExerciseOrder - 1];
        },

        getStringStatus: () => {
            return (status) => statusExerciseEnum.getStringStatus(status);
        }
    },
    actions: {
        setCurrentExercise(exerciseId) {
            this.currentIdExercise = exerciseId;
            this.currentExercise = this.exercises.find(({ id }) => id === exerciseId);
        },

        nextExercise(currentOrder) {
            const exercise = this.exercises.find(exercise => exercise.order === currentOrder + 1);

            this.currentIdExercise = exercise.id;
            this.currentExercise = exercise;
        },

        previousExercise(currentOrder) {
            const exercise = this.exercises.find(exercise => exercise.order === currentOrder - 1);

            this.currentIdExercise = exercise.id;
            this.currentExercise = exercise;
        },

        getExerciseGroups() {
            return {
                'Tutorial': 
                    {exercises: this.exercises.filter(exercise => exercise.group === groupsExercisesEnum.TUTORIAL)},
                'Trilha de Comandos': 
                    {exercises: this.exercises.filter(exercise => exercise.group === groupsExercisesEnum.TRILHA_DE_COMANDOS)},
                'Trilha de Partidas': 
                    {exercises: this.exercises.filter(exercise => exercise.group === groupsExercisesEnum.TRILHA_DE_PARTIDAS)}
            };
        },

        async updateStatusInProgress(idExercise, statusExercise) {
            if(statusExercise === statusExerciseEnum.NOT_STARTED) {
                await api().put(`/exercise/${idExercise}/status`, { status: statusExerciseEnum.IN_PROGRESS });
            }
        },

        async updateStatusCompleted(idExercise) {
            await api().put(`/exercise/${idExercise}/status`, { status: statusExerciseEnum.COMPLETED });
        },

        async saveLog(logContent) {
            await api().post('/log/exercise/' + this.currentIdExercise, { content: logContent });
        }
    }
})