<template>
    <div class="pillBadge d-flex justify-content-center align-itens-center">
        <p class="m-0">{{ props.statusExercise }}</p>
    </div>
</template>

<script setup>
    import { defineProps } from 'vue';

    const props = defineProps({
        statusExercise: {
            type: String,
            required: true
        }
    });

</script>

<style scoped>
.pillBadge {
    min-width: 110px;
    background-color: #4eca6b;
    color: white;
    border-radius: 30px;
}
</style>