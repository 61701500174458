<template>
    <div class="header custom-background-blue d-flex justify-content-center align-items-center">
        <div class="w-80 d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-center align-items-center">
                <h4 @click="navigateToExercisesList" class="text-light m-0 link-list-exercise">Laboratório Digital</h4>
                <div class="responsive divider bg-light mx-5"></div>
                <p class="responsive text-light m-0" >Acionamentos elétricos industriais</p>
            </div>
            <div class="d-flex flex-sm-row flex-column justify-content-center align-items-center">
                <ModalFeedback ref="modalFeedbackRef" />
                <button @click="openModalFeedback" 
                    class="custom-button-feedback custom-color-blue me-sm-0 me-md-5"
                >Feedback</button>
                <button @click="logoutUser"
                    class="custom-button-logout d-flex justify-content-center align-items-center"
                > 
                    Sair
                    <img :src="ImageLogoutButton" 
                        class="ms-2" 
                        alt="Icone de porta com uma flecha no meio que aponta para a direita"
                    >
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
    import ModalFeedback from '@/components/Modals/ModalFeedback.vue';
    import ImageLogoutButton from "@/assets/images/logoutButton.svg";
    import { useRouter } from 'vue-router';
    import { ref } from 'vue';
    import { useAuthStore } from '@/stores/auth';


    const router = useRouter();
    const modalFeedbackRef = ref(null);
    const authStore = useAuthStore();
    
    async function logoutUser () {
        const responseLogout = await authStore.logout();

        if(responseLogout.success) {
            router.push({ name: 'Login' });
        }
    }

    function openModalFeedback() {
        modalFeedbackRef.value.openModal();
    }

    function navigateToExercisesList() {
        router.push({ name: 'ListExercises' });
    }
</script>

<style scoped>
.header {
    min-height: 6.5%;
    max-height: 6.5%;
}

@media only screen and (max-width: 575px) {
    .header { 
        max-height: 12% !important; 
        min-height: 12% !important; 
    }
    .custom-button-feedback { margin-bottom: 0.5rem; }
}

@media only screen and (max-width: 1000px) {
    .responsive { display: none;}
}

@media only screen and (max-height: 750px) {
    .header { 
        max-height: 12% !important; 
        min-height: 12% !important; 
    }
}

.divider {
    width: 2px;
    height: 42px;
}

.custom-button-feedback {
    background-color: #E8EFFB;
    width: 100px;
    height: 35px;
    border-radius: 8px;
    outline: none;
    border: 1px;
    transition: 0.2s linear;
}

.custom-button-feedback:hover {
    background-color: #d1ddf3;
}

.custom-button-logout {
    background: transparent;
    width: 85px;
    height: 35px;
    color: #E8EFFB;
    outline: none;
    border: none;
}

.link-list-exercise {
    cursor: pointer;
    transition: linear .2s;
}

.link-list-exercise:hover {
    color: #d1ddf3 !important;
}
</style>