import { defineStore } from 'pinia';
import api from '@/api/api';

export const useAuthStore = defineStore('auth', {
    state: () => {
        return {
            access_token: null,
            refresh_token: null,
            refreshTokenTimeout: null
        }
    },
    actions: {
        async login(loginInfos) {
            try {
                const responseLogin = await api().post('/auth/login', loginInfos);

                if(responseLogin) {
                    const { data } = responseLogin;
    
                    this.access_token = data.access_token;
                    this.refresh_token = data.refresh_token;
        
                    this.startCounterRefreshToken();
                    return { success: true }
                } 

                return { success: false }
                
            } catch (error) {
                return { success: false }
            }
        },

        async refreshToken() {
            try {
                const responseRefresh = await api().post('/auth/refresh', { refresh_token: this.refresh_token })

                if(responseRefresh) {
                    const { data } = responseRefresh;

                    this.access_token = data.access_token;
                    this.refresh_token = data.refresh_token;
                    this.startCounterRefreshToken();
                    return;
                }
            } catch (error) {
                this.access_token = null;
                this.refresh_token = null;
    
                window.postMessage("login-expirado");
            }
        },

        startCounterRefreshToken() {
            const jwtBase64 = this.access_token.split('.')[1];
            const jwtToken = JSON.parse(atob(jwtBase64));
            
            const expires = new Date(jwtToken.exp * 1000);
            const timeout = expires.getTime() - Date.now() - (60 * 1000);

            this.refreshTokenTimeout = setTimeout(this.refreshToken, timeout);
        },

        resetCounterRefresh() {
            if(this.access_token) {
                this.stopCounterRefreshToken();
                this.startCounterRefreshToken();
            }
        },

        stopCounterRefreshToken() {
            clearTimeout(this.refreshTokenTimeout);
            this.refreshTokenTimeout = null;
        },

        async logout() {
            try {
                const responseLogout = await api().post('/auth/logout', {refresh_token: this.refresh_token});

                if(responseLogout) {
                    this.access_token = null;
                    this.refresh_token = null;
                    this.stopCounterRefreshToken();
                    
                    return { success: true }
                }
            } catch (error) {
                return { success: false }
            }
        },

    },
    persist: [
        {pick: ['access_token', 'refresh_token'], storage: sessionStorage },
        {pick: ['refreshTokenTimeout'], storage: localStorage },
    ],
})
