<template>
    <div class="row h-100 m-0">
        <ModalPrivacyPolicy ref="modalPrivacyPolicyRef"/>
        <div class="col-6 col-xxl-3 background d-none d-sm-none d-md-block p-0 rounded-start rounded-5"></div>
        <div class="col p-0 d-flex flex-column justify-content-center align-items-center">
            <h4>Bem vindo(a) ao</h4>
            <h1>Laboratório Digital</h1>
            <p class="mb-4">Inicie sua jornada de aprendizado.</p>

            <div class="d-flex flex-column align-items-center form-login w-100 mt-2">
                <div class="col-12 col-xxl-4 form-floating mb-3">
                    <input id="inputUsuario" 
                        type="text" 
                        class="form-control border-secondary-subtle" 
                        placeholder="email@example.com"
                        v-model="state.user"
                        @keyup.enter="loginUser" 
                    >
                    <label for="inputUsuario">Usuário</label>
                </div>
                <div class="col-12 col-xxl-4 form-floating mb-3">
                    <input id="inputPasword" 
                        type="password"     
                        class="form-control border-secondary-subtle" 
                        placeholder="Password"
                        v-model="state.password"
                        @keyup.enter="loginUser" 
                    >
                    <label for="inputPasword">Senha</label>
                </div>
                <p v-if="state.errorMessage" class="text-danger font-size-error">{{ state.errorMessage }}</p>
                <button @click="loginUser" 
                    type="button" 
                    class="btn btn-primary btn-login custom-background-blue col-12 col-xxl-4 mt-4"
                >Entrar</button>
                <button @click="openModalPolicyPrivacy" class="btn btn-link mt-4">Politica de privacidade</button>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useRouter } from 'vue-router';
    import { reactive, ref } from 'vue';
    import ModalPrivacyPolicy from '@/components/Modals/ModalPrivacyPolicy.vue';

    import { useAuthStore } from '@/stores/auth';

    const modalPrivacyPolicyRef = ref(null);
    const authStore = useAuthStore();
    const router = useRouter();

    const state = reactive({
        user: '',
        password: '',
        errorMessage: null
    });

    async function loginUser() { 
        if(validaInputs()) { 
            state.errorMessage = 'Usuário ou senha inválidos';
            return; 
        }

        const loginInfos = {
            username: state.user,
            password: state.password,
        };

        state.errorMessage = null; 

        const responseLogin = await authStore.login(loginInfos);

        if(responseLogin.success) {
            router.push({ name: 'ListExercises' });
            return;
        }

        state.errorMessage = 'Usuário ou senha inválidos';
    }

    function validaInputs() {
        if(
            (state.user === '' && state.password === '') || 
            state.password.length < 8
        ) { return true; }

        return false;
    }

    function openModalPolicyPrivacy() {
        modalPrivacyPolicyRef.value.openModal();
    }
</script>

<style scoped>
    .background {
        background-image: url('@/assets/images/backgroundLogin.png');
        background-size: cover;
    }

    .form-login {
        padding-right: 4rem;
        padding-left: 4rem;
    }

    .btn-login {
        height: 3.6rem;
        font-size: 1.2rem;
    }
</style>