<template>
	<div class="footer custom-background-blue d-flex justify-content-center align-items-center">
		<p class="text-light m-0">SENAI/SC {{ getCurrentYear() }}. Todos os direitos reservados.</p>
	</div>
</template>

<script setup>
	function getCurrentYear() {
		const date = new Date();

		return date.getFullYear();
	} 
</script>

<style scoped>
.footer {
	min-height: 6.5%;
	max-height: 6.5%;
}

@media only screen and (max-height: 750px) {
    .footer { 
        max-height: 12% !important; 
        min-height: 12% !important; 
    }
}
</style>