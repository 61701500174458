<template>
    <MainStructure>
        <div class="content-list-exercise d-flex flex-column align-items-center">
            <div class="tille mt-2 w-80 d-flex flex-column">
                <h4>Laboratório de acionamentos elétricos industriais</h4>
                <p>Acesse os níveis abaixo para exercitar práticas de comando e partida.</p>
            </div>
            <div v-if="state.loading" class="spinner-border custom-color-blue mt-4">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div 
                class="w-100 d-flex flex-column align-items-center "
                v-for="(exerciseGroup, index) in store.getExerciseGroups()"
                :key="index"
            >       
                <div class="w-80 mt-3">
                    <h4 v-if="!state.loading" class="align-self-start text-muted m-0">{{ index }}</h4>
                </div>
                <div class="cards d-flex justify-content-center flex-wrap">
                    <p v-if="state.withoutExercises" class="font-size-error">Sem exercícios para exibir</p>
                    <CardExercise 
                        v-else
                        v-for="exercise in exerciseGroup.exercises" 
                        :key="exercise.id" 
                        :exercise="exercise"
                    />
                </div>
            </div>
        </div>
    </MainStructure>
</template>

<script setup>
    import MainStructure from '@/components/layoutParts/MainStructure.vue';
    import CardExercise from '@/components/layoutParts/CardExercise.vue';
    import { inject, onMounted, reactive } from 'vue';

    import { useExerciseStore } from '@/stores/exercise';

    const state = reactive({
        withoutExercises: false,
        loading: false
    });
    
    const axios = inject('axios');
    const store = useExerciseStore();

    onMounted(() => {
        loadExercises();
    })

    function loadExercises() {
        state.loading = true;
        store.exercises = []
        axios().get('/exercise').then(({ data }) => {
            state.withoutExercises = false;
            state.loading = false;
            store.exercises = data;
        }).catch(() => {
            state.loading = false;
            state.withoutExercises = true;
        })
    }
</script>

<style scoped>
    .cards {
        height: 100%;
        width: 100%;
    }

    .content-list-exercise {
        max-width: 100%;
        min-width: 100%;
        max-height: 98%;
        min-height: 98%;
    }
</style>
