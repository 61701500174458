<template>
	<div class="modal fade" 
		id="modalPrivacyPolicy" 
		tabindex="-1" 
		aria-hidden="true"
		data-bs-backdrop="static" 
		data-bs-keyboard="false" 
	>
		<div class="modal-dialog modal-fullscreen">
			<div class="modal-content widthModal">
				<div class="modal-header custom-background-blue d-flex align-items-center justify-content-between">
					<h5 class="modal-title text-light">Política de Privacidade</h5>
                    <button 
						type="button" 
						class="btn btn-primary d-flex align-items-center justify-content-center custom-button-close p-1"
						data-bs-dismiss="modal"
					>
						<AppIcons icon="x-lg" size="20" />
					</button>
				</div>
				<div class="modal-body">
					<embed style="width: 100%; height: 100%" :src="policyPrivacy" />
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
    import AppIcons from '@/components/layoutParts/AppIcons.vue';
    import policyPrivacy from '@/documents/Política de Privacidade-EPT.pdf';
    import { onMounted, reactive, defineExpose } from 'vue';
    import { Modal } from 'bootstrap';

    const state = reactive({
		modalPrivacyPolicy: null,
	});

    onMounted(() => {
		state.modalPrivacyPolicy = new Modal('#modalPrivacyPolicy', {});
	})

    defineExpose({
		openModal
	})

    function openModal() {
		state.modalPrivacyPolicy.show();
	}
</script>

<style scoped>
	.custom-button-close {
		background-color: white;
		color: rgb(173, 173, 173) !important;
	}

	.custom-button-close:hover {
		background-color: rgb(173, 173, 173)!important;
		color: rgb(59, 59, 59) !important;
	}
</style>