import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';

const router = createRouter({
    history: createWebHistory(),
    routes
})

const withoutLogin = [
    "Login"
]

router.beforeEach((to, _, next) => {
    const tokens = JSON.parse(sessionStorage.getItem('auth'));

    const hasToken = tokens ? Object.keys(tokens).length > 0 : null;

    if(withoutLogin.includes(to.name) && hasToken) {
        next({ name: 'ListExercises' });
        return;
    }

    if(!withoutLogin.includes(to.name) && !hasToken) {
        next({ name: 'Login' });
        return;
    }
    
    next();
});

export default router;
