<template>
	<RouterView />
</template>

<script setup>
import { RouterView } from 'vue-router';
import { onMounted } from 'vue';
import { useAuthStore } from '@/stores/auth';

const authstore = useAuthStore();

onMounted(() => {
	authstore.resetCounterRefresh();
})

</script>

<style>
	#app {
		width: 100%;
		height: 100vh;
	}
</style>
