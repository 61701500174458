<template>  
    <div 
        class="custom-card-exercise p-3 mx-2 my-3" 
        :class="{ completed: props.exercise.status === statusExerciseEnum.COMPLETED }"
        @click="navigateToExercise(props.exercise.id)"
    >
        <div class="d-flex justify-content-between align-items-center">
            <div 
                class="iconContainer d-flex justify-content-center align-items-center"
                :class="{'customColorEngine': props.exercise.icon === 'electric-motor'}"
            >
                <img 
                    :src="getIconExercise(props.exercise.icon)" 
                    alt="Ícone com a representação do que o exercício aborda"
                    width="35"
                >

            </div>
            <BadgeStatusExercise 
                v-if="props.exercise.status === statusExerciseEnum.COMPLETED"
                :statusExercise="store.getStringStatus(props.exercise.status)"
            />
        </div>
        <div class="mt-3">
            <h5 class="custom-tittle-text overflow-auto">{{ props.exercise.title }}</h5>
            <BadgeNumberExercise
                class="me-2" 
                :numberExercise="props.exercise.order"
                :iconName="props.exercise.icon"
            />
            <span class="badge badge-pill badge-success">Success</span>

            <div class="overflow-auto custom-text-card">
                <p>{{ props.exercise.description }}</p>
            </div>
        </div>
    </div>

</template>

<script setup>
    import BadgeNumberExercise from '@/components/layoutParts/BadgeNumberExercise.vue';
    import { useRouter } from 'vue-router';
    import { defineProps } from 'vue';
    import { useExerciseStore } from '@/stores/exercise';
    import BadgeStatusExercise from '@/components/layoutParts/BadgeStatusExercise.vue';
    import statusExerciseEnum from '@/enums/statusExerciseEnum';

    const router = useRouter();
    const store = useExerciseStore();

    const props = defineProps({
        exercise: {
            type: Object,
            required: true
        }
    });

    function getIconExercise(iconName) {
        return require("@/assets/images/iconsExercises/"+iconName+".svg");
    }

    function navigateToExercise(idExercise) {
        store.setCurrentExercise(idExercise);

        router.push({ name: 'Exercise' });
    }
</script>

<style scoped>
.custom-card-exercise {
    max-width: 325px;
    min-width: 325px;
    max-height: 275px;
    min-height: 275px;
    border: 2px solid #E8EFFB;
    border-radius: 16px;
    background-color: white;
    cursor: pointer;
    transition: .2s;
}

.completed {
     border: 2px solid #4eca6b !important;
}

.custom-card-exercise:hover {
    background: #f4f7fc;
    transform: scale(1.06);
}

.custom-text-card {
    color: #8C8C8C;
    max-height: 75px;
}

.custom-tittle-text {
     max-height: 50px;
}

.iconContainer {
    min-width: 52px;
    max-width: 52px;
    min-height: 52px;
    background-color: #E8EFFB;
    border-radius: 8px;
}

.customColorEngine {
    background-color: #c7dfe2 !important;
}
</style>
