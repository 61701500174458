<template>
	<div>
		<div class="modal fade" 
			id="modalFeecback" 
			tabindex="-1" 
			aria-hidden="true"
			data-bs-backdrop="static" 
			data-bs-keyboard="false" 
		>
			<div class="modal-dialog modal-lg modal-dialog-centered">
				<div class="modal-content widthModal">
					<div class="modal-header custom-background-blue d-flex align-items-center justify-content-between">
						<h5 class="modal-title text-light">feedback</h5>
						<button 
							type="button" 
							class="btn btn-primary d-flex align-items-center justify-content-center custom-button-close p-1"
							data-bs-dismiss="modal"
						>
							<AppIcons icon="x-lg" size="20" />
						</button>
					</div>
				<div class="modal-body">
					<p class="custom-text m-0 mb-1">Dê seu feedback</p>
					<textarea 
						class="form-control" 
						aria-label="With textarea"
						rows="8"
						placeholder="Texto..."
						v-model="state.textFeedback"
					></textarea>

					<div class="d-flex flex-column align-items-center justify-content-center ">
						<p class="custom-text m-0 mb-1 mt-3">Avalie a sua Experiência</p>
						<div>
							<AppIcons 
								v-for="star in state.stars"
								:key="star.value"
								class="color-star me-2" 
								:icon="star.icon" 
								size="20"
								@mouseover="feedbackStars(star.value)"
								@mouseleave="cleanStars"
								@click="saveStatusStars(star.value)"
							/>
						</div>
					</div>
				</div>
					<div class="modal-footer d-flex align-items-center justify-content-between">
						<div class="w-75 d-flex align-items-center justify-content-center">
							<p v-if="state.erroSaveFeecback" class="text-danger m-0 font-size-error">Ocoreu um erro ao salvar feedback</p>
						</div>
						<button @click="saveFeedback" type="button" class="btn btn-primary align-self-end">Salvar</button>
					</div>
				</div>
			</div>
		</div>
		<div 
			class="modal fade" 
			id="modalFeecbackSaved" 
			tabindex="-1" 
			aria-hidden="true"
			data-bs-backdrop="static" 
			data-bs-keyboard="false" 
		>
			<div class="modal-dialog modal-lg modal-dialog-centered">
				<div class="modal-content widthModal">
					<div class="modal-header custom-background-blue d-flex align-items-center justify-content-between">
						<h5 class="modal-title text-light">Obrigado!</h5>
						<button 
							type="button" 
							class="btn btn-primary d-flex align-items-center justify-content-center custom-button-close p-1"
							data-bs-dismiss="modal"
						>
							<AppIcons icon="x-lg" size="20"/>
						</button>
					</div>
					<div class="modal-body d-flex flex-column align-items-center justify-content-center">
						<AppIcons icon="check2-circle" size="80" classIcon="text-success"/>
						<h5 class="mt-4">Feedback salvo com sucesso!</h5>
					</div>
					<div class="modal-footer">
						<button @click="state.modalFeedbacksaved.hide()" type="button" class="btn btn-primary">Ok!</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import AppIcons from '@/components/layoutParts/AppIcons.vue';
	import { defineExpose, reactive, onMounted, inject } from 'vue';
	import { Modal } from 'bootstrap';

	const state = reactive({
		modalFeedback: null,
		modalFeedbacksaved: null,
		stars: [
			{value: 1, icon: 'star'},
			{value: 2, icon: 'star'},
			{value: 3, icon: 'star'},
			{value: 4, icon: 'star'},
			{value: 5, icon: 'star'}
		],
		statusStar: 0,
		textFeedback: '',
		erroSaveFeecback: false
	});

	const axios = inject('axios');

	onMounted(() => {
		state.modalFeedback = new Modal('#modalFeecback', {});
		state.modalFeedbacksaved = new Modal('#modalFeecbackSaved', {});
	})

	defineExpose({
		openModal
	}) 

	function openModal() {
		state.modalFeedback.show()
	}

	function closeModal() {
		state.modalFeedback.hide();
	}

	function feedbackStars(value) {
		if(state.statusStar > 0) { 
			return 
		}

		cleanStars();

		state.stars = state.stars.map(star => {
			if(star.value <= value) {
				star.icon = 'star-fill';
			}
			return star;
		})
	}

	function cleanStars() {
		if(state.statusStar === 0) {
			state.stars.forEach(star => star.icon = 'star');
		}
	}

	function saveStatusStars(value) {
		state.statusStar = 0;
		feedbackStars(value);
		state.statusStar = value;
	}

	function saveFeedback () {
		const feedback = {
			content: state.textFeedback,
			grade: state.statusStar
		};

		axios().post('/feedback', { ...feedback }).then(() => {
			state.textFeedback = '';
			state.statusStar = 0;
			state.erroSaveFeecback = false;
			cleanStars();
			closeModal();
			state.modalFeedbacksaved.show();
		}).catch(() => {
			state.erroSaveFeecback = true;
		})
	}

</script>

<style scoped>
.custom-button-close {
	background-color: white;
	color: rgb(173, 173, 173) !important;
}

.custom-button-close:hover {
	background-color: rgb(173, 173, 173)!important;
	color: rgb(59, 59, 59) !important;
}

.custom-text {
	font-size: 0.8rem;
}

.widthModal {
	width: 750px !important;
}

.color-star {
	color: #EAAA08;
	cursor: pointer;
}

.color-star:last-child {
	margin: 0 !important;
} 
</style>