<template>
    <div 
        class="pillBadge d-flex justify-content-center align-itens-center"
        :class="{'customColorEngine': props.iconName === 'electric-motor'}"
    >
        <p class="m-0">Exercício {{ props.numberExercise }}</p>
    </div>
</template>

<script setup>
    import { defineProps } from 'vue';

    const props = defineProps({
        numberExercise: {
            type: Number,
            required: true
        },
        iconName: {
            type: String,
            required: true
        }
    })

</script>

<style scoped>
.pillBadge {
    max-width: 110px;
    min-width: 110px;
    background-color: #E8EFFB;
    color: #1651B5;
    border-radius: 4px;
}

.customColorEngine {
    background-color: #c7dfe2 !important;
    color: #004e51 !important;
}

@media only screen and (max-width: 470px) {
    .pillBadge { 
        display: none !important;
    }
}
</style>