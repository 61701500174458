<template>
    <div class="offcanvas offcanvas-end" 
        tabindex="-1" 
        id="chatBot" 
        data-bs-backdrop="false" 
    >
        <div class="offcanvas-header custom-header rounded-bottom-4">
            <h5 class="offcanvas-title">Assistente de laboratório</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body d-flex flex-column">
            <div v-if="state.errorBot" class="alert alert-danger" role="alert">
                Houve um problema ao conectar com o tutor
            </div>

            <div v-if="state.loadingHistoryChat" class="w-100 d-flex align-items-center justify-content-center">
                <div class="spinner-grow spinner-grow-sm custom-color-blue"></div>
                <p class="m-0 ms-3 mb-2">Carregando mensagens...</p>
            </div>

            <div class="messages" ref="messagesContainerRef">
                <div 
                    v-for="chat in state.messages" 
                    :key="chat.text" 
                    :class="[chat.bot ? 'd-flex align-items-center justify-content-start' : 'd-flex justify-content-end']"    
                >
                    <img 
                        v-if="chat.bot" 
                        class="align-self-start rounded me-2" :src="botImageChat" 
                        alt="representação de um robo amigavel com um sorriso"
                    >
                    <p :class="[chat.bot ? 'textBot p-3' : 'textUser p-3 text-light align-self-end']">
                        {{ chat.text }}
                    </p>
                </div>

                <div v-if="state.botLoading" class="d-flex align-items-center justify-content-start">
                    <img class="align-self-start rounded me-2" :src="botImageChat" 
                        alt="representação de um robo amigavel com um sorriso"
                    >
                    <span class="textBot p-3">
                        <div class="spinner-grow text-secondary widthLoading me-1">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-secondary widthLoading me-1">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-secondary widthLoading me-1">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </span>
                </div>
            </div>
            <div class="textField d-flex align-items-center justify-content-center">
                <div class="input-group">
                    <input 
                        @keyup.enter="sendMessage" 
                        type="text" 
                        class="form-control border-end-0 customInput" 
                        placeholder="Digite alguma coisa..."
                        v-model="state.currentMesssageUser"
                    >
                    <button 
                        @click="sendMessage" 
                        class="btn border border-start-0 buttonCustom" 
                        id="button-send-mesage-chat-bot"
                    >
                        <img :src="sendIcon" alt="Ícone de triangulo apontando para a direita azul">
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import botImageChat from '@/assets/images/botImageChat.svg';
    import sendIcon from '@/assets/images/sendMesageIcon.svg';
    import { onMounted, reactive, defineExpose, inject, ref, nextTick } from 'vue';
    import { Offcanvas } from 'bootstrap';
    import { useExerciseStore } from '@/stores/exercise';

    const state = reactive({
        chatBot: null,
        messages: [],
        currentMesssageUser: '',
        botLoading: false,
        sessionExercise: null,
        errorBot: false,
        loadingHistoryChat: true,
    });

    const messagesContainerRef = ref(null);
    const store = useExerciseStore();
    const axios = inject('axios');

    onMounted(() => {
		state.chatBot = new Offcanvas('#chatBot', {});
        state.errorBot = false;
	})

    defineExpose({
		openChatBot, closeChatBot, cleanChat
	})

    async function getSessionExercise() {
        const { data } = await axios().get(`/exercise/${store.currentIdExercise}/session`);
        state.sessionExercise = data.session_id;
    }

    function closeChatBot() {
        state.chatBot.hide();
    }

    function cleanChat() {
        state.messages = [];
    }

    async function openChatBot() {
		state.chatBot.show();

        await getSessionExercise();

        if(state.messages.length == 0 && !state.botLoading) {

            addMessageChat('Olá, sou seu assistente de laboratório. Como posso ajudar?', true);

            state.loadingHistoryChat = true;
            
            axios().get('/chat', {headers : { "X-CHAT-SESSION": state.sessionExercise }}).then(({ data }) => {
                if(data.history.length > 0) {
                    data.history.forEach(chat => {
                        const isBot = chat.type === 'ai';  

                        addMessageChat(chat.content, isBot);
                    })
                }
                state.loadingHistoryChat = false;
            }).catch(() => {
                state.errorBot = true;
            })
        }
	}

    async function addMessageChat(text, bot = false) {
        state.messages.push({text, bot});

        await nextTick();

        scrollToEndChat();
    }

    function sendMessage() {
        if(state.currentMesssageUser.length > 0) {
            addMessageChat(state.currentMesssageUser, false);
            state.botLoading = true;

            const messageChat = {
                input: state.currentMesssageUser,
                exercise_id: store.currentIdExercise
            }

            state.currentMesssageUser = '';

            const configRoute = {
                headers: {
                    "X-CHAT-SESSION": state.sessionExercise 
                }
            }

            axios().post('/chat', messageChat, configRoute).then(({ data }) => {
                addMessageChat(data.response, true);
                state.sessionExercise = data.session_id;
                state.botLoading = false;
            }).catch(() => {
                state.errorBot = true;
                state.botLoading = false;
            })
        }
    }

    function scrollToEndChat() {
        messagesContainerRef.value.scrollTop = messagesContainerRef.value.scrollHeight;
    }

</script>

<style scoped>
.custom-header {
    background-color: #F6F6F6;
}

.messages {
    height: 90%;
    overflow-y: auto;
}

.textField {
    height: 10%;
}

.customInput {
    height: 4rem;
    font-size: 0.9rem;
}

.customInput:focus {
    outline: 0;
    box-shadow:none;
    border-color: #1A5FD5;
}

.customInput:focus + .buttonCustom{
    border-color: #1A5FD5 !important;
}

.buttonCustom:hover {
    background-color: #F6F6F6;
}

.textBot {
    background-color: #E8EFFB;
    border-radius: 0 2rem 2rem 2rem;
    font-size: 0.9rem;
    max-width: 85%;
}

.textUser {
    background-color: #1A5FD5;
    border-radius: 2rem 0 2rem 2rem;
    font-size: 0.9rem;
    max-width: 85%;
}

.widthLoading {
    width: 8px !important;
    height: 8px !important;
}

.widthLoading:first-child {
    animation-delay: .1s !important;
}

.widthLoading:nth-child(2n) {
    animation-delay: .2s !important;
}

.widthLoading:last-child {
    animation-delay: .3s !important;
}
</style>