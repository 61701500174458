import { createApp } from 'vue'
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './App.vue'

import router from '@/routes/routerConfig';
import api from '@/api/api';

import './assets/style.css'
import 'bootstrap/dist/css/bootstrap.min.css'; 

const app = createApp(App);
const pinia = createPinia();

pinia.use(piniaPluginPersistedstate);

app.use(pinia);
app.use(router);
app.provide('axios', api);

window.addEventListener("message", (ev) => {
    if (ev.data == "login-expirado") {
        router.push({ name: "Login" })
    }
});

app.mount('#app');
