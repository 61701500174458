<template>
    <MainStructure>
        <ChatBot ref="chatBotRef"/>
        <div class="content d-flex flex-column align-items-center">
            <div class="header mt-2 d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center justify-content-center">
                    <h4 class="m-0 me-4">{{ exerciseStore.currentExercise.title }}</h4>
                    <BadgeNumberExercise 
                        :numberExercise="exerciseStore.currentExercise.order"
                    />
                </div>
              
                <div class="buttons ms-2">
                     <button @click="navigateExercise('next')"
                        type="button" 
                        class="mb-2 width-button btn btn-primary custom-background-blue d-flex align-items-center justify-content-center"
                        :disabled="exerciseStore.hasNextExercise(exerciseStore.currentExercise.order) ? false : true"
                     >
                        Próximo
                        <AppIcons
                            class="ms-2"
                            icon="arrow-right"
                            size="20"
                        />
                    </button>

                     <button @click="navigateExercise('previous')"
                        type="button" 
                        class="width-button btn btn-primary custom-background-blue d-flex align-items-center justify-content-center"
                        :disabled="exerciseStore.hasPreviousExercise(exerciseStore.currentExercise.order) ? false : true"
                     >
                        <AppIcons
                            class="me-2"
                            icon="arrow-left"
                            size="20"
                        />
                        Anterior
                    </button>
                </div>
            </div>
            <div class="d-flex justify-content-center flex-wrap mt-2 mb-2 vw-100 h-100">
                <iframe
                    ref="exerciseIFrameRef"    
                   :src="exerciseStore.currentExercise.url"
                    title="Laboratório de acionamentos elétricos"
                    allowfullscreen
                    class="sizeSimulator"
                />
            </div>
            <div class="w-100 d-flex align-items-center justify-content-end"
                :class="{'justify-content-between': isMobileDevice()}"
            >
                 <button @click="fullScreeenMode"
                    type="button" 
                    class="mt-2 width-button btn btn-primary custom-background-blue d-flex align-items-center justify-content-center"
                    :class="{'d-none': !isMobileDevice()}"
                >
                    Tela Cheia
                    <AppIcons
                        class="ms-2"
                        icon="arrows-fullscreen"
                        size="20"
                    />
                </button>

                <button @click="openChatBot"
                    type="button" 
                    class="mt-2 width-button btn btn-primary custom-background-blue d-flex align-items-center justify-content-center"
                >
                    Tutor
                    <AppIcons
                        class="ms-2"
                        icon="robot"
                        size="20"
                    />
                </button>
            </div>
        </div>
    </MainStructure>
</template>

<script setup>
    import MainStructure from '@/components/layoutParts/MainStructure.vue';
    import BadgeNumberExercise from '@/components/layoutParts/BadgeNumberExercise.vue';
    import ChatBot from '@/components/layoutParts/ChatBot.vue';
    import AppIcons from '@/components/layoutParts/AppIcons.vue';
    import statusExerciseEnum from '@/enums/statusExerciseEnum';

    import { ref, onMounted, onUnmounted} from 'vue';
    import { useExerciseStore } from '@/stores/exercise';

    const exerciseStore = useExerciseStore();

    const chatBotRef = ref(null);
    const exerciseIFrameRef = ref(null);

    onMounted(() => {
        exerciseStore.updateStatusInProgress(exerciseStore.currentExercise.id, exerciseStore.currentExercise.status);

        window.addEventListener('message', handleEventsExercise);
    });

    onUnmounted(() => {
        window.removeEventListener('message', handleEventsExercise);
    });

    function navigateExercise(action = 'next') {

        if(action === 'next') {
            exerciseStore.nextExercise(exerciseStore.currentExercise.order);
        } else {
            exerciseStore.previousExercise(exerciseStore.currentExercise.order);
        }

        chatBotRef.value.closeChatBot();
        chatBotRef.value.cleanChat();

        exerciseStore.updateStatusInProgress(exerciseStore.currentExercise.id, exerciseStore.currentExercise.status);
    }

    function handleEventsExercise(event) {
        const message = event.data;

        if(event.origin !== process.env.VUE_APP_EXERCISE_URL) { return; }

        switch(message.type) {
            case 'status': 
                if(message.data === 'completed' && exerciseStore.currentExercise.status !== statusExerciseEnum.COMPLETED) {
                    exerciseStore.updateStatusCompleted(exerciseStore.currentExercise.id);
                } 
                break;
            case 'log': 
                exerciseStore.saveLog(message.data);
                break;
        }
    }

    function openChatBot() {
        chatBotRef.value.openChatBot();
    }

    function fullScreeenMode() {
        if (exerciseIFrameRef.value.requestFullscreen) {
            exerciseIFrameRef.value.requestFullscreen();
        } else if (exerciseIFrameRef.value.webkitRequestFullscreen) { 
            exerciseIFrameRef.value.webkitRequestFullscreen();
        }
    }

    function isMobileDevice() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            return true;
        }

        return false;
    }
</script>

<style scoped>
.header {
    width: 100%
}

.sizeSimulator {
    position: relative;
    width: 90%;
    height: 102%;
}

.width-button {
    width: 140px;
}

</style>
