<template>
    <MainStructure>
        <div class="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
            <h2>Página não encontrada - 404</h2>    
            <button @click="redirectListExercises"
                type="button" 
                class="width-button btn btn-primary custom-background-blue d-flex align-items-center justify-content-center"
            >
                Voltar a lista de exercícios
            </button>
        </div>
    </MainStructure>
</template>

<script setup>
    import MainStructure from '@/components/layoutParts/MainStructure.vue';
    import { useRouter } from 'vue-router';

    const router = useRouter();

    function redirectListExercises() {
         router.push({ name: 'ListExercises' });
    }
</script>
