import axios from 'axios';
const api = () => {
    const tokens = JSON.parse(sessionStorage.getItem('auth'));

    const hasToken = tokens ? Object.keys(tokens).length > 0 : null;

    const configAxios = axios.create({
        baseURL: process.env.VUE_APP_API_URL,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'ngrok-skip-browser-warning': 'true',
            ... hasToken ?  { 'Authorization' : `Bearer ${ tokens.access_token }` } : {},
        },
    });

    return configAxios
}

export default api;
