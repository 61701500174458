const status = {
    NOT_STARTED: "NOT_STARTED",
    IN_PROGRESS: "IN_PROGRESS",
    COMPLETED: "COMPLETED",
    getStringStatus: statusString
}

function statusString(statusExercise) {
    switch (statusExercise) {
        case status.NOT_STARTED:
            return "Não iniciado";
        case status.IN_PROGRESS:
            return "Em andamento";
        case status.COMPLETED:
            return "Concluído"; 
    }
}

export default status; 
